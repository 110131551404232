import { AdsTableProvider } from './hooks/useAdsTable'
import Table from './Table'

const AdsTable = ({
  campaignAdType,
  campaignStatus
}: Pick<AdsTableProviderProps, 'campaignAdType' | 'campaignStatus'>) => (
  <AdsTableProvider
    campaignAdType={campaignAdType}
    campaignStatus={campaignStatus}
  >
    <Table />
  </AdsTableProvider>
)

export default AdsTable
