import { useEffect, useRef, useState } from 'react'
import { FiSearch } from 'react-icons/fi'

import { t } from 'i18next'
import { debounce } from 'lodash'

import Input from '~/components/Form/Input'
import { history } from '~/helpers'
import useQueryParams from '~/hooks/useQuery'

const PublisherListFilters = () => {
  const [innerLoading, setInnerLoading] = useState(false)

  const searchParams = useQueryParams()

  const publisher_name = searchParams.get('publisher_name')

  const [data, setData] = useState({
    publisher_name
  })

  useEffect(() => {
    setData({
      publisher_name: publisher_name || ''
    })
  }, [publisher_name])

  const executeDebounceFunc = (func: () => void) => func()

  const handleDebounce = useRef(debounce(executeDebounceFunc, 1000)).current

  const handleFilter = (data: OnChangeInputData) => {
    const { name, value } = data

    if (!value) {
      const newParams = new URLSearchParams(searchParams.toString())
      newParams.delete(name)
      history.push({ search: newParams.toString() })
      setInnerLoading(false)

      return
    }

    searchParams.set(name, String(value))
    searchParams.set('page', '1')
    history.push({ search: searchParams.toString() })

    setInnerLoading(false)
  }

  const handleChange = (data: OnChangeInputData) => {
    setInnerLoading(true)

    setData(prevState => ({ ...prevState, [data.name]: data.value }))

    handleDebounce(() => handleFilter(data))
  }

  return (
    <form className="d-flex gap-2 mb-2">
      <Input
        name="publisher_name"
        onChange={handleChange}
        value={data.publisher_name}
        icon={FiSearch}
        placeholder={t('rm:filter.publisherName')}
        type="search"
        loading={innerLoading}
      />
    </form>
  )
}

export default PublisherListFilters
