import { useEffect, useRef, useState } from 'react'
import { FiSearch } from 'react-icons/fi'

import { t } from 'i18next'
import { debounce } from 'lodash'

import { ButtonNavLink } from '~/components/Buttons'
import Input from '~/components/Form/Input'
import { history } from '~/helpers'
import useQueryParams from '~/hooks/useQuery'

import FilterPublisherTag from '../../Filters/FilterPublisherTag'

const AdvertisersFilters = () => {
  const [innerLoading, setInnerLoading] = useState(false)

  const searchParams = useQueryParams()

  const advertiser_name = searchParams.get('advertiser_name')
  const seller_id = searchParams.get('seller_id')

  const [data, setData] = useState({
    advertiser_name,
    seller_id
  })

  useEffect(() => {
    setData({
      advertiser_name: advertiser_name || '',
      seller_id: seller_id || ''
    })
  }, [advertiser_name, seller_id])

  const executeDebounceFunc = (func: () => void) => func()

  const handleDebounce = useRef(debounce(executeDebounceFunc, 1000)).current

  const handleFilter = (data: OnChangeInputData) => {
    const { name, value } = data

    if (!value) {
      const newParams = new URLSearchParams(searchParams.toString())
      newParams.delete(name)
      history.push({ search: newParams.toString() })
      setInnerLoading(false)

      return
    }

    searchParams.set(name, String(value))
    searchParams.set('page', '1')
    history.push({ search: searchParams.toString() })

    setInnerLoading(false)
  }

  const handleChange = (data: OnChangeInputData) => {
    setInnerLoading(true)

    setData(prevState => ({ ...prevState, [data.name]: data.value }))

    handleDebounce(() => handleFilter(data))
  }

  return (
    <form className="d-flex gap-2 mb-2">
      <Input
        name="advertiser_name"
        onChange={handleChange}
        value={data.advertiser_name}
        icon={FiSearch}
        placeholder={t('rm:filter.advertiserName')}
        type="search"
        loading={innerLoading}
      />

      <Input
        name="seller_id"
        onChange={handleChange}
        value={data.seller_id}
        icon={FiSearch}
        placeholder={t('rm:config.advertisers.filter.sellerId')}
        type="search"
        loading={innerLoading}
      />

      <FilterPublisherTag />

      <ButtonNavLink
        to="/retail-media/config/advertisers/create"
        text={t('rm:config.advertisers.create.menu')}
        size="small"
        className="ms-auto"
      />
    </form>
  )
}

export default AdvertisersFilters
