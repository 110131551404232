import { useState } from 'react'
import { toastr } from 'react-redux-toastr'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Form } from '@unform/web'
import { t } from 'i18next'

import { Button } from '~/components/Buttons'
import { FormGroup } from '~/components/unform'
import { addKeywordsBulkService } from '~/modules/retailMedia/services/campaign-keyword'

import PlacementSearch from '../../CampaignForm/elements/Targeting/Keywords'

type FormNewCampaignKeyWordProps = {
  campaignId: string
  callback?: (data?: unknown) => void
}

type HandleSubmitProps = {
  keywords: string
  desktop: {
    keywords: string
  }
}

const FormNewCampaignKeyWord = ({
  campaignId,
  callback
}: FormNewCampaignKeyWordProps) => {
  const [buttonLoadingKeyword, setButtonLoadingKeyword] = useState(false)

  const QUERY_KEY = ['get-keywords', campaignId]
  const queryClient = useQueryClient()

  const addKeywordMutation = useMutation({
    mutationFn: addKeywordsBulkService,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY
      })

      setButtonLoadingKeyword(false)

      toastr.success(
        t('rm:toast.success'),
        t('rm:campaign.form.new.keyword.success.message')
      )
      callback()
    },
    onError: () => {
      setButtonLoadingKeyword(false)
      toastr.error(
        t('rm:campaign.form.new.keyword.error'),
        t('rm:campaign.form.new.keyword.error.message')
      )
    }
  })

  const handleSubmit = async ({ keywords, desktop }: HandleSubmitProps) => {
    setButtonLoadingKeyword(true)

    const allKeywords = `${desktop.keywords},${keywords}`
    const keywordsArray = allKeywords.split(',').filter(Boolean)

    if (!keywordsArray.length) {
      setButtonLoadingKeyword(false)
      toastr.error(
        t('rm:toast.error'),
        t('rm:campaign.form.new.keyword.warn.empty.message')
      )
      return
    }
    await addKeywordMutation.mutateAsync({
      campaignId,
      keywords: keywordsArray
    })
  }

  return (
    <section className="px-3 pb-3">
      <Form onSubmit={handleSubmit}>
        <PlacementSearch />

        <hr />

        <FormGroup className="actionButtons">
          <Button
            onClick={callback}
            text={t('rm:button.Cancel')}
            template="transparentDanger"
          />
          <Button
            type="submit"
            text={t('rm:button.Save')}
            template="success"
            loading={buttonLoadingKeyword}
          />
        </FormGroup>
      </Form>
    </section>
  )
}

export default FormNewCampaignKeyWord
