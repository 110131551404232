import { MediaRejectedTableProvider } from './hooks/useMediaRejectedTable'
import Table from './Table'

const AdsRejectedTable = () => (
  <MediaRejectedTableProvider>
    <Table />
  </MediaRejectedTableProvider>
)

export default AdsRejectedTable
