import { MediaReviewTableProvider } from './hooks/useMediaReviewTable'
import Table from './Table'

const MediaReviewTable = () => (
  <MediaReviewTableProvider>
    <Table />
  </MediaReviewTableProvider>
)

export default MediaReviewTable
