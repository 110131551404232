import AlertInfo from '~/components/AlertInfo'
import HeaderPages from '~/components/HeaderPages'
import ListPagination from '~/components/ListPagination'
import Loading from '~/components/Loading'
import LoadingPopUp from '~/components/LoadingPopUp'
import Panel from '~/components/Panel'
import Table from '~/components/Table'
import { useAlert } from '~/hooks/useAlert'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import ExportButton from '~/modules/retailMedia/components/ExportButton'
import { Footer } from '~/pages/products/list/styles'

import CampaignListFilters from '../CampaignFilters'
import { useCampaignTable } from './hooks/useCampaignTable'

const CampaignTable = () => {
  const {
    data,
    error,
    isValidating,
    innerLoading,
    page,
    perPage,
    total,
    handlePagination,
    handleItemsPerPageChange,
    sortDirection,
    sortKey,
    handleSortList,
    schema,
    queryParams
  } = useCampaignTable()

  const { t } = useAPPTranslation()

  const [AlertOnError] = useAlert(error)

  return (
    <>
      <HeaderPages
        title={t('rm:listingCampaigns')}
        sideRightComponent={
          <ExportButton queryParams={queryParams} exportType={'campaigns'} />
        }
      />

      <CampaignListFilters />

      <Loading status={!data && isValidating}>Carregando campanhas...</Loading>

      <LoadingPopUp isActive={isValidating} />

      <AlertOnError />

      <Panel noPaddingBody isLoading={!data}>
        <Loading status={innerLoading} onlyLine />

        {!!data && (
          <Table
            schema={schema}
            data={data}
            total={total}
            sideBorder
            smallerRowPadding
            activeSortDirection={sortDirection}
            activeSortKey={sortKey}
            onSort={handleSortList}
            freeze="first-row-and-column"
          />
        )}

        {total === 0 && data && (
          <AlertInfo
            template="warning"
            text={t('rm:metrics.campaigns.notFoundWithFilters')}
          />
        )}
      </Panel>

      <Footer>
        <ListPagination
          total={total}
          label={total > 1 ? t('rm:Campaigns') : t('rm:Campaign')}
          currentPage={page}
          itemsPerPage={perPage}
          menuPlacement="top"
          onClickPagination={handlePagination}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      </Footer>
    </>
  )
}

export default CampaignTable
