import { useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'
import { t } from 'i18next'

import EmptyPage from '~/components/empty-page'
import ListPagination from '~/components/ListPagination'
import Loading from '~/components/Loading'
import Panel from '~/components/Panel'
import Table from '~/components/Table'
import { history } from '~/helpers'
import useQueryParams from '~/hooks/useQuery'
import {
  getCampaignsInReviewKey,
  getCampaignsInReviewService
} from '~/modules/retailMedia/services/campaign'
import { Footer } from '~/pages/products/list/styles'

import { formatCampaignInReviewList } from './DTO'
import { schema } from './schema'

const ReviewCampaignTable = () => {
  const params = useQueryParams()
  const page = Number(params.get('page')) || 1
  const quantity = Number(params.get('quantity')) || 50
  const advertiserId = params.get('rmid')

  const { data, isPending: loading } = useQuery({
    queryKey: [getCampaignsInReviewKey, page, quantity],
    queryFn: async () => {
      const response = await getCampaignsInReviewService({
        page,
        quantity,
        account_info: true,
        advertiser_id: advertiserId
        // order_by: 'created_at',
        // order_direction: 'ASC'
      })

      return {
        ...response?.data,
        data: formatCampaignInReviewList(response?.data?.data)
      }
    }
  })

  const handlePagination = useCallback(
    (value: number) => {
      params.set('page', String(value))
      history.push({ search: params.toString() })
    },
    [params]
  )

  const handleItemsPerPageChange = useCallback(
    (value: { value: number }) => {
      params.set('quantity', String(value.value))
      params.set('page', '1')
      history.push({ search: params.toString() })
    },
    [params]
  )

  return (
    <>
      <Loading status={loading}>{t('rm:reviewCampaign.loading')}</Loading>

      {data?.total === 0 && !loading && (
        <EmptyPage
          title={t('rm:reviewCampaign.notFoundCampaign')}
          subtitle={t('rm:reviewCampaign.subtitle.notFoundCampaign')}
          titleButton={t('rm:clearFilters')}
          svgPath="photos/orders-empty"
        />
      )}

      {data?.total > 0 && (
        <>
          <Panel noPaddingBody isLoading={loading}>
            {!!data && (
              <Table
                schema={schema()}
                data={data.data}
                smallerRowPadding
                sideBorder
              />
            )}
          </Panel>

          <Footer>
            <ListPagination
              total={data.total}
              label={data.total > 1 ? t('rm:Campaigns') : t('rm:Campaign')}
              currentPage={page}
              itemsPerPage={quantity}
              menuPlacement="top"
              onClickPagination={handlePagination}
              onItemsPerPageChange={handleItemsPerPageChange}
            />
          </Footer>
        </>
      )}
    </>
  )
}

export default ReviewCampaignTable
