import i18n from '~/i18n'
import {
  formatIntervalDates,
  getLabelAdType
} from '~/modules/retailMedia/dtos/common/campaign/format'

export const formatCampaignInReviewList = (data: CampaignInReview[]) => {
  if (!data) return null

  return data.map(campaign => ({
    _raw: {
      originPath:
        'src/modules/retailMedia/components/_tables/ReviewCampaignTable/DTO.ts'
    },
    raw: campaign,
    id: campaign.campaign_id,
    title: campaign.name,
    createdAt: campaign.created_at,
    pending: campaign.pending.map(item => ({
      quantity: item.quantity,
      // @ts-expect-error - i18n count is not typed
      label: i18n.t(`rm:${item.label}`, { count: item.quantity })
    })),
    adType: getLabelAdType(campaign.ad_type),
    status: campaign.status,
    advertiser: {
      name: campaign?.advertiser_name,
      logo: campaign?.advertiser_account_logo,
      theme: { ...campaign?.advertiser_account_theme }
    },
    ...formatIntervalDates({
      start_at: campaign.start_at,
      end_at: campaign.end_at,
      ignoreUTC: true
    })
  })) as FormatCampaignInReviewList[]
}
