import { FiChevronRight } from 'react-icons/fi'
import { NavLink } from 'react-router-dom'

import { ButtonNavLink } from '~/components/Buttons'
import { LogoStore } from '~/components/LogoStore'
import Status from '~/components/Status'
import { Badge } from '~/components/UI/Badge/'
import { SmallLineClamp } from '~/components/UI/SmallLineClamp'
import { formatDateToString, isReadOnly } from '~/helpers'
import i18n from '~/i18n'

import { statusType } from '../../StatusBar/statusType'

const { t } = i18n

export const schema: () => TableSchema<FormatCampaignInReviewList> = () => ({
  campaignName: {
    title: t('rm:Campaign'),
    render: rowData => (
      <NavLink
        to={`/retail-media/campaign/view/${rowData.id}?callback_url=/retail-media/campaigns/review`}
        title={t('rm:clickToReview')}
      >
        <div style={{ lineHeight: 1 }}>
          <strong className="title">{rowData.title}</strong>
          <br />
          <small>{t('rm:reviewCampaign')}</small>
        </div>
      </NavLink>
    ),
    className: 'order__details col__infos col__title'
  },
  advertiserName: {
    title: t('rm:Advertiser'),
    render: rowData => (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '.25rem',
          maxWidth: '10rem'
        }}
      >
        {rowData.advertiser.logo && (
          <LogoStore
            alt={rowData.advertiser.name}
            backgroundLogo={
              rowData.advertiser.theme.header_color ||
              rowData.advertiser.theme.primary_color
            }
            linkLogo={rowData.advertiser.logo}
            size="small"
          />
        )}

        <SmallLineClamp>{rowData.advertiser.name}</SmallLineClamp>
      </div>
    )
  },
  type: {
    title: t('rm:Type'),
    render: rowData => rowData.adType
  },
  status: {
    title: t('rm:Status'),
    render: rowData => (
      <Status
        status={{
          label: statusType[rowData.status]?.label || rowData.status,
          type: statusType[rowData.status]?.color || 'default'
        }}
      />
    )
  },
  createdAt: {
    title: t('rm:creationDate'),
    render: rowData =>
      formatDateToString(rowData.createdAt, 'DD/MM/YY [às] HH:mm')
  },
  start: {
    title: t('rm:Start'),
    render: rowData => rowData.startDate
  },
  end: {
    title: t('rm:End'),
    render: rowData => rowData.endDate || t('rm:indefinite')
  },
  pending: {
    title: t('rm:ReviewCampaignTable.column.pending'),
    render: rowData => (
      <>
        {rowData.pending.map(item => (
          <Badge
            iconLeft={item.quantity}
            text={item.label}
            key={item.label}
            size="small"
          />
        ))}
      </>
    )
  },
  ...(isReadOnly()
    ? {}
    : {
        reviewCampaign: {
          width: '196',
          render: rowData => (
            <ButtonNavLink
              to={`/retail-media/campaign/view/${rowData.id}?callback_url=/retail-media/campaigns/review`}
              size="small"
              text={t('rm:reviewCampaign')}
              iconRight={<FiChevronRight />}
            />
          )
        }
      })
})
